<template>
  <DashboardContainer>
    <Spinner v-if="loading" class="mt-24" />
    <template v-else>
      <!-- Donation list (smallest breakpoint only) -->
      <div class="sm:hidden">
        <template v-if="isGivarooStaff || isNPOAdmin || isCanvassingOrgAdmin">
          <div class="flex items-center mb-4">
            <input
              name="start"
              type="date"
              class="input--text--small placeholder:text-gray-400"
              placeholder="Select date start"
              @input="getDateRangeOption1($event.target.value)"
            />
            <p class="mx-2 text-sm text-gray-500">to</p>
            <input
              name="start"
              type="date"
              class="input--text--small"
              placeholder="Select date end"
              @input="getDateRangeOption2($event.target.value)"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex mb-2">
              <select
                class="input--text--small mr-2"
                @change="filterByCampaign($event.target.value)"
              >
                <option :selected="!selectedCampaignFilter.id" :value="''">All Campaigns</option>
                <option
                  v-for="campaign in campaignFilters"
                  :key="campaignFilters.indexOf(campaign)"
                  :value="campaign.id"
                >
                  {{ campaign.location ? campaign.name + ' ' + campaign.location : campaign.name }}
                </option>
              </select>
              <select class="input--text--small" @change="filterByFundraiser($event.target.value)">
                <option :selected="!selectedFundraiserFilter.id" :value="''">
                  All Fundraisers
                </option>
                <option
                  v-for="fundraiser in fundraiserFilters"
                  :key="fundraiserFilters.indexOf(fundraiser)"
                  :value="fundraiser.id"
                >
                  {{ fundraiser.fullName }}
                </option>
              </select>
            </div>
            <div class="flex mb-2">
              <select
                class="input--text--small mr-2"
                @change="filterByFrequency($event.target.value)"
              >
                <option :selected="!selectedFrequencyFilter.value" :value="''">
                  All Frequencies
                </option>
                <option
                  v-for="frequency in frequencyFilters"
                  :key="frequencyFilters.indexOf(frequency)"
                  :value="frequency"
                >
                  {{ formatToTitleCase(frequency) }}
                </option>
              </select>
              <select class="input--text--small" @change="filterByStatus($event.target.value)">
                <option :selected="!selectedStatusFilter.value" :value="''">All Statuses</option>
                <option
                  v-for="status in statusFilters"
                  :key="statusFilters.indexOf(status)"
                  :value="status"
                >
                  {{ status }}
                </option>
              </select>
            </div>
          </div>
          <button
            @click="exportDonations"
            v-if="isGivarooStaff || isNPOAdmin || isCanvassingOrgAdmin"
            class="btn--table--export max-w-fit mt-8 mb-2"
          >
            Export Donations
          </button>
        </template>
        <Spinner v-if="updatingTable" class="mt-8" />
        <template v-else>
          <ul
            v-if="donations.list?.length"
            role="list"
            class="mt-2 rounded-lg divide-y divide-gray-200 overflow-hidden sm:hidden"
            :class="{ shadow: !isGivarooStaff }"
          >
            <li
              v-for="(donation, key) in donations.list"
              :key="key"
              class="block bg-white px-4 py-4"
            >
              <div class="flex items-center space-x-4">
                <div class="flex flex-1 space-x-2 truncate">
                  <div class="flex w-full text-sm text-gray-500">
                    <div class="truncate">
                      <strong class="truncate block text-black pr-4">
                        {{ donation.donorRef.firstName }} {{ donation.donorRef.lastName }}
                      </strong>
                      <em class="truncate">{{
                        donation.campaign.location
                          ? donation.campaign.name + ' ' + donation.campaign.location
                          : donation.campaign.name
                      }}</em>
                      <div class="flex w-full">
                        <span>
                          {{ formatCurrency(donation.amount) }},
                          {{ formatToTitleCase(donation.frequency.value) }}
                        </span>
                      </div>
                    </div>
                    <div class="ml-auto text-right flex flex-col justify-between">
                      <span class="block">{{ formatDateString(donation.datetimeCreated) }}</span>
                      <span class="">ID: {{ donation.givarooId }}</span>
                      <span>Status: {{ getStatus(donation.status) }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <p v-else class="mt-2 p-4 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden">
            No results found.
          </p>
        </template>
      </div>

      <!-- Donation list (small breakpoint and up) -->
      <div class="hidden sm:block">
        <template v-if="isGivarooStaff || isNPOAdmin || isCanvassingOrgAdmin">
          <div class="grid grid-cols-4 gap-2">
            <div class="flex items-center mb-2 col-span-2">
              <input
                name="start"
                type="date"
                class="input--text--small"
                placeholder="Select date start"
                @input="getDateRangeOption1($event.target.value)"
              />
              <p class="mx-2 text-sm text-gray-500">to</p>
              <input
                name="start"
                type="date"
                class="input--text--small"
                placeholder="Select date end"
                @input="getDateRangeOption2($event.target.value)"
              />
            </div>
          </div>
          <div class="grid grid-cols-4 gap-2">
            <select class="input--text--small" @change="filterByCampaign($event.target.value)">
              <option :selected="!selectedCampaignFilter.id" :value="''">All Campaigns</option>
              <option
                v-for="campaign in campaignFilters"
                :key="campaignFilters.indexOf(campaign)"
                :value="campaign.id"
              >
                {{ campaign.location ? campaign.name + ' ' + campaign.location : campaign.name }}
              </option>
            </select>
            <select class="input--text--small" @change="filterByFundraiser($event.target.value)">
              <option :selected="!selectedFundraiserFilter.id" value="">All Fundraisers</option>
              <option
                v-for="fundraiser in fundraiserFilters"
                :key="fundraiserFilters.indexOf(fundraiser)"
                :value="fundraiser.id"
              >
                {{ fundraiser.fullName }}
              </option>
            </select>
            <select class="input--text--small" @change="filterByFrequency($event.target.value)">
              <option :selected="!selectedFrequencyFilter.value" value="">All Frequencies</option>
              <option
                v-for="frequency in frequencyFilters"
                :key="frequencyFilters.indexOf(frequency)"
                :value="frequency"
              >
                {{ formatToTitleCase(frequency) }}
              </option>
            </select>
            <select class="input--text--small" @change="filterByStatus($event.target.value)">
              <option :selected="!selectedStatusFilter.value" value="">All Statuses</option>
              <option
                v-for="status in statusFilters"
                :key="statusFilters.indexOf(status)"
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </div>
          <div class="my-2 flex sm:flex-row flex-col mb-8">
            <div
              class="input--filter flex justify-between mr-2 hover:cursor-pointer"
              :class="{ invisible: !selectedCampaignFilter.name }"
              @click="clearCampaignFilter()"
            >
              <p class="text-sm font-bold">
                {{
                  selectedCampaignFilter.location
                    ? selectedCampaignFilter.name + ' ' + selectedCampaignFilter.location
                    : selectedCampaignFilter.name
                }}
              </p>
              <XMarkIcon class="h-5" />
            </div>
            <div
              class="input--filter flex justify-between mr-2 hover:cursor-pointer"
              :class="{ invisible: !selectedFundraiserFilter.name }"
              @click="clearFundraiserFilter()"
            >
              <p class="text-sm font-bold">{{ selectedFundraiserFilter.name }}</p>
              <XMarkIcon class="h-5" />
            </div>
            <div
              class="input--filter flex justify-between mr-2 hover:cursor-pointer"
              :class="{ invisible: !selectedFrequencyFilter.value }"
              @click="clearFrequencyFilter()"
            >
              <p class="text-sm font-bold">
                {{ formatToTitleCase(selectedFrequencyFilter.value) }}
              </p>
              <XMarkIcon class="h-5" />
            </div>
            <div
              class="input--filter flex justify-between hover:cursor-pointer"
              :class="{ invisible: !selectedStatusFilter.value }"
              @click="clearStatusFilter()"
            >
              <p class="text-sm font-bold">{{ selectedStatusFilter.value }}</p>
              <XMarkIcon class="h-5" />
            </div>
          </div>
        </template>
        <div class="mt-2 flex flex-col">
          <button
            @click="exportDonations"
            v-if="isGivarooStaff || isNPOAdmin || isCanvassingOrgAdmin"
            class="btn--table--export max-w-fit mb-2"
          >
            Export Donations
          </button>
          <div class="min-w-full overflow-hidden overflow-x-auto align-middle sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold black"
                  >
                    Donor
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Campaign
                  </th>
                  <th
                    v-if="isGivarooStaff || isNPOAdmin || isCanvassingOrgAdmin"
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Fundraiser
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Frequency
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    ID
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-if="updatingTable">
                  <td colspan="8" class="whitespace-nowrap py-10 text-sm text-gray-900">
                    <Spinner />
                  </td>
                </tr>
                <template v-else-if="!updatingTable && donations.list?.length">
                  <tr v-for="(donation, key) in donations.list" :key="key">
                    <td class="whitespace-nowrap py-4 px-6 text-sm text-black">
                      {{ donation.donorRef.firstName }} {{ donation.donorRef.lastName }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{ formatDateString(donation.datetimeCreated) }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{
                        donation.campaign.location
                          ? donation.campaign.name + ' ' + donation.campaign.location
                          : donation.campaign.name
                      }}
                    </td>
                    <td
                      v-if="isGivarooStaff || isNPOAdmin || isCanvassingOrgAdmin"
                      class="whitespace-nowrap py-4 px-6 text-sm text-gray-500"
                    >
                      {{ donation.fundraiser ? donation.fundraiser.fullName : 'N/A' }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{ formatCurrency(donation.amount) }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{ formatToTitleCase(donation.frequency.value) }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{ getStatus(donation.status) }}
                    </td>
                    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                      {{ donation.givarooId }}
                    </td>
                  </tr>
                </template>
                <tr v-else-if="!updatingTable && !donations.list?.length">
                  <td colspan="8" class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    No results found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </DashboardContainer>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, triggerRef } from 'vue'
import { useStore } from 'vuex'
import DashboardContainer from '@/components/DashboardContainer.vue'
import Spinner from '@/components/Spinner'
import { donationFunctions, DonationApi } from '@/services/donations/'
import options from '@/services/donations/options/'
import { XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'DonationList',
  components: {
    DashboardContainer,
    Spinner,
    XMarkIcon,
  },
  setup() {
    const store = useStore()
    const isGivarooStaff = ref(store.getters.isGivarooStaff)
    const isNPOAdmin = ref(store.getters.isNPOAdmin)
    const isCanvassingOrgAdmin = ref(store.getters.isCanvassingOrgAdmin)

    const { donationCollection, donationFilters } = donationFunctions()
    const donations = ref(donationCollection)
    const donationRefs = ref([])
    const donationStatuses = reactive(options.DONATION_STATUS_CHOICES)

    const loading = ref(false)
    const updatingTable = ref(false)

    const campaignFilters = computed(() =>
      donationRefs.value ? getUniqueObjectsArray(donationRefs.value, 'campaign') : null,
    )
    const fundraiserFilters = computed(() =>
      donationRefs.value ? getUniqueObjectsArray(donationRefs.value, 'fundraiser') : null,
    )
    const frequencyFilters = computed(() =>
      donationRefs.value ? getUniqueObjectsArray(donationRefs.value, 'frequency', true) : null,
    )
    const statusFilters = computed(() =>
      donationRefs.value
        ? [...new Set(donationRefs.value.map((donation) => getStatus(donation.status)))]
        : [],
    )

    const selectedCampaignFilter = reactive({
      location: '',
      name: '',
      id: '',
    })
    const selectedFundraiserFilter = reactive({
      name: '',
      id: '',
    })
    const selectedFrequencyFilter = reactive({
      value: '',
    })
    const selectedStatusFilter = reactive({
      value: '',
    })
    const selectedDateRange = reactive({
      date1: '',
      date2: '',
    })

    const getUniqueObjectsArray = (arr, key, value = false) => {
      const filteredArr = arr.filter((obj) => obj[`${key}`] != null)

      if (value) {
        return Object.values(
          filteredArr
            .map((obj) => obj[`${key}`])
            .reduce((acc, obj) => ({ ...acc, [obj.value]: obj.value }), {}),
        )
      } else {
        return Object.values(
          filteredArr
            .map((obj) => obj[`${key}`])
            .reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}),
        )
      }
    }

    onBeforeMount(async () => {
      loading.value = true
      await donations.value.refresh().catch((error) => {
        alert(error)
      })
      triggerRef(donations)
      donationRefs.value = donations.value.list
      loading.value = false
    })

    const setFilters = () => {
      donationFilters.campaign = selectedCampaignFilter.id
      donationFilters.frequency = selectedFrequencyFilter.value
      donationFilters.fundraiser = selectedFundraiserFilter.id
      donationFilters.status = selectedStatusFilter.value.toUpperCase()
      getStartAndEndDates()
    }

    const getDonations = async () => {
      updatingTable.value = true
      setFilters()
      await donations.value.refresh().catch((error) => {
        alert(error)
      })
      triggerRef(donations)
      updatingTable.value = false
    }

    const formatDate = (date) => {
      return new Date(date)
    }

    const formatDateString = (date) => {
      return formatDate(date).toLocaleDateString()
    }

    const formatDateQueryParam = (date) => {
      return formatDate(date).toISOString().split('T')[0]
    }

    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount)
    }

    const formatToTitleCase = (value) => {
      // eslint-disable-next-line
      return value.replace(/(?<=\b)\w/g, (word) => word.toUpperCase())
    }

    const getStatus = (donationStatus) => {
      return donationStatuses.find((status) => status.id == donationStatus).name
    }

    const filterByCampaign = (option) => {
      if (option) {
        let selectedCampaign = campaignFilters.value.find((campaign) => campaign.id == option)
        selectedCampaignFilter.name = selectedCampaign.name
        selectedCampaignFilter.location = selectedCampaign.location
        selectedCampaignFilter.id = selectedCampaign.id
        getDonations()
      } else {
        clearCampaignFilter()
      }
    }

    const filterByFundraiser = (option) => {
      if (option) {
        let selectedFundraiser = fundraiserFilters.value.find(
          (fundraiser) => fundraiser.id == option,
        )
        selectedFundraiserFilter.name = selectedFundraiser.fullName
        selectedFundraiserFilter.id = selectedFundraiser.id
        getDonations()
      } else {
        clearFundraiserFilter()
      }
    }

    const filterByFrequency = (option) => {
      if (option) {
        selectedFrequencyFilter.value = option
        getDonations()
      } else {
        clearFrequencyFilter()
      }
    }

    const filterByStatus = (option) => {
      if (option) {
        selectedStatusFilter.value = option
        getDonations()
      } else {
        clearStatusFilter()
      }
    }

    const filterByDate = () => {
      if (
        (selectedDateRange.date1 && selectedDateRange.date2) ||
        (!selectedDateRange.date1 && !selectedDateRange.date)
      ) {
        getDonations()
      }
    }

    const getDateRangeOption1 = (date) => {
      if (date) {
        // eslint-disable-next-line
        date = date.replace(/-/g, '\/')
        selectedDateRange.date1 = formatDateQueryParam(date)
      } else {
        selectedDateRange.date1 = ''
      }
      filterByDate()
    }
    const getDateRangeOption2 = (date) => {
      if (date) {
        // eslint-disable-next-line
        date = date.replace(/-/g, '\/')
        selectedDateRange.date2 = formatDateQueryParam(date)
      } else {
        selectedDateRange.date2 = ''
      }
      filterByDate()
    }
    const getStartAndEndDates = () => {
      let date1 = selectedDateRange.date1
      let date2 = selectedDateRange.date2
      if (date1 && date2) {
        if (date1 >= date2) {
          donationFilters.dateRangeStart = date2
          donationFilters.dateRangeEnd = date1
        } else if (date1 <= date2) {
          donationFilters.dateRangeStart = date1
          donationFilters.dateRangeEnd = date2
        }
      } else if (!date1 || !date2) {
        donationFilters.dateRangeStart = donationFilters.dateRangeEnd = ''
      }
    }

    const clearCampaignFilter = () => {
      Object.keys(selectedCampaignFilter).map((key) => (selectedCampaignFilter[key] = ''))
      getDonations()
    }

    const clearFundraiserFilter = () => {
      Object.keys(selectedFundraiserFilter).map((key) => (selectedFundraiserFilter[key] = ''))
      getDonations()
    }

    const clearFrequencyFilter = () => {
      Object.keys(selectedFrequencyFilter).map((key) => (selectedFrequencyFilter[key] = ''))
      getDonations()
    }

    const clearStatusFilter = () => {
      Object.keys(selectedStatusFilter).map((key) => (selectedStatusFilter[key] = ''))
      getDonations()
    }

    const exportDonations = () => {
      setFilters()
      const { ordering, ...filters } = donationFilters
      DonationApi.csc.generateExport({ ...filters })
    }

    return {
      donations,
      donationRefs,
      donationFilters,
      isGivarooStaff,
      isNPOAdmin,
      isCanvassingOrgAdmin,
      loading,
      updatingTable,
      campaignFilters,
      fundraiserFilters,
      frequencyFilters,
      statusFilters,
      selectedCampaignFilter,
      selectedFundraiserFilter,
      selectedFrequencyFilter,
      selectedStatusFilter,
      selectedDateRange,
      formatDate,
      formatDateString,
      formatCurrency,
      formatToTitleCase,
      getStatus,
      filterByCampaign,
      filterByFundraiser,
      filterByFrequency,
      filterByStatus,
      getDateRangeOption1,
      getDateRangeOption2,
      clearCampaignFilter,
      clearFundraiserFilter,
      clearFrequencyFilter,
      clearStatusFilter,
      exportDonations,
    }
  },
}
</script>
